import React from 'react';

import type { Nullable } from 'common/src/types/utils';

import ProgressivePictureButton from '../ProgressivePictureButton';
import AlternativeImage from '../AlternativeImage';

type ImageItemProps = {
  image: Nullable<string>;
  altText?: string;
  className?: string;
  containerButtonClassName?: string;
  previewWidth?: number;
  previewHeight?: number;
};

const desktopSrcSets = (
  image: string,
  previewWidth: number,
  previewHeight: number,
) =>
  [
    `${image.replace('_WxH', `_${previewWidth}x${previewHeight}`)} 1x`,
    `${image.replace('_WxH', `_${previewWidth * 2}x${previewHeight * 2}`)} 2x`,
    `${image.replace('_WxH', `_${previewWidth * 4}x${previewHeight * 4}`)} 4x`,
  ].join(', ');

const ImageItem = ({
  altText,
  className,
  containerButtonClassName,
  image,
  previewHeight = 252,
  previewWidth = 300,
}: ImageItemProps) =>
  image ? (
    <ProgressivePictureButton
      className={className}
      containerButtonClassName={containerButtonClassName}
      sources={[
        {
          srcSet: [desktopSrcSets(image, previewWidth, previewHeight)],
          media: '(min-width: 517px)',
        },
      ]}
      alt={altText || ''}
      src={image}
    />
  ) : (
    <AlternativeImage className={className} alt={altText || ''} />
  );

ImageItem.defaultProps = {
  image: null,
  altText: null,
  className: null,
  previewWidth: 300,
  previewHeight: 252,
  containerButtonClassName: null,
};

export default ImageItem;
