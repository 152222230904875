import React from 'react';
import type { CSSProperties } from 'react';

import BpkCard from '@skyscanner/backpack-web/bpk-component-card';
import { cssModules } from '@skyscanner/backpack-web/bpk-react-utils';
import BpkText, {
  TEXT_STYLES,
} from '@skyscanner/backpack-web/bpk-component-text';

import type { I18nService } from 'common/src/types/i18n';
import type { Nullable } from 'common/src/types/utils';
import { PRICE_TYPE_TOTAL } from 'common/src/services/price-type';

import {
  withI18n,
  FormattedCurrency,
} from '../../../skyscanner-application/i18n';
import HotelStars from '../../HotelStars';
import ReviewRating from '../../ReviewRating';
import Distance from '../../Distance';
import DistancePOI from '../../DistancePOI';
import CardImage from '../../CardImage';

import type { HotelCard } from '../../../types/hotel-card';

import STYLES from './MiniCard.scss';

type MiniCardProps = {
  i18n: I18nService;
  hotel: HotelCard;
  position: number;
  showPrice?: boolean;
  onCardClicked?: Function;
  isVertical?: boolean;
  priceType?: Nullable<string>;
  className?: string;
  style?: CSSProperties;
};

const cls = cssModules(STYLES);

const MiniCard = ({
  className,
  hotel,
  i18n,
  isVertical,
  onCardClicked,
  position,
  priceType,
  showPrice,
  style,
}: MiniCardProps) => {
  const {
    distanceInfo,
    id: hotelId,
    image,
    name: hotelName,
    numberOfStars,
    priceInfo,
    relevantPOI,
    reviewsSummaryInfo,
    url,
  } = hotel;

  const cug = priceInfo?.cug;
  const originalPrice = cug && cug.originalPrice;

  const trackSearchResultSelected = () => {
    onCardClicked &&
      onCardClicked({
        id: hotelId, // for mini card on Dayview desktop map
        hotelId, // for mini card on HomePage desktop viewed hotel card
        position,
        isMainPrice: true,
        isGoToHD: true,
        mainPriceArea: 'viewedCardOnDesktop',
        isViewedHotel: true,
        partnerId: priceInfo?.partnerId,
        price: priceInfo?.price,
        basePrice: priceInfo?.basePrice,
        taxesAndFees: priceInfo?.taxesAndFees,
      });
  };

  const distanceVariantNode = distanceInfo ? (
    <BpkText tagName="p" className={cls('MiniCard__distanceVariant')}>
      <Distance distance={distanceInfo} />
    </BpkText>
  ) : null;

  const distancePOINode = relevantPOI ? (
    <BpkText tagName="p" className={cls('MiniCard__distanceVariant')}>
      <DistancePOI relevantPOI={relevantPOI} />
    </BpkText>
  ) : (
    distanceVariantNode
  );

  const priceInfoNode = priceInfo?.price ? (
    <>
      {originalPrice && (
        <BpkText
          textStyle={TEXT_STYLES.caption}
          className={cls('MiniCard__originalPrice')}
        >
          <FormattedCurrency price={originalPrice} />
        </BpkText>
      )}
      <BpkText textStyle={TEXT_STYLES.heading5}>
        {i18n.formatCurrency(priceInfo.price)}
      </BpkText>
      <BpkText
        textStyle={TEXT_STYLES.caption}
        className={cls('MiniCard__priceLabel', 'MiniCard__hotelName')}
      >
        {priceType === PRICE_TYPE_TOTAL
          ? i18n.translate('dayView_hotel_card_total')
          : i18n.translate('dayView_hotel_card_a_night')}
      </BpkText>
    </>
  ) : null;

  return (
    <BpkCard
      href={url}
      padded={false}
      atomic={false}
      onClick={trackSearchResultSelected}
      target="_blank"
      rel="opener"
      className={className || cls('MiniCard')}
      style={style}
    >
      <div
        className={cls(
          'MiniCard__container',
          showPrice && 'MiniCard__container--withPrice',
          isVertical && 'MiniCard__container--vertical',
        )}
      >
        <div
          className={cls(
            'MiniCard__imageInfo',
            isVertical && 'MiniCard__imageInfo--vertical',
          )}
        >
          <CardImage
            className={cls('MiniCard__img')}
            image={image}
            name={hotelName}
          />
        </div>
        <div
          className={cls(
            'MiniCard__baseInfo',
            showPrice && 'MiniCard__baseInfo--withPrice',
            isVertical && 'MiniCard__baseInfo--vertical',
          )}
        >
          <div
            className={cls(
              'MiniCard__hotelAndStars',
              showPrice && 'MiniCard__hotelAndStars--withPrice',
              isVertical && 'MiniCard__hotelAndStars--vertical',
            )}
          >
            <BpkText
              textStyle={TEXT_STYLES.label1}
              className={cls('MiniCard__hotelName')}
            >
              {hotelName}
            </BpkText>
            {numberOfStars! > 0 && (
              <HotelStars
                stars={numberOfStars}
                className={cls(
                  'MiniCard__stars',
                  showPrice && 'MiniCard__stars--withPrice',
                  isVertical && 'MiniCard__stars--vertical',
                )}
              />
            )}
          </div>

          <div
            className={cls(
              'MiniCard__ratingAndDistanceInfo',
              showPrice && 'MiniCard__ratingAndDistanceInfo--withPrice',
            )}
          >
            {reviewsSummaryInfo && reviewsSummaryInfo.reviewSummaryScore ? (
              <ReviewRating
                score={reviewsSummaryInfo.reviewSummaryScore}
                count={reviewsSummaryInfo.reviewSummaryCount}
                image={reviewsSummaryInfo.reviewSummaryScoreImageUrl}
                className={cls(
                  'MiniCard__reviewRating',
                  showPrice && 'MiniCard__reviewRating--withPrice',
                )}
              />
            ) : null}
            <div
              className={cls(
                'MiniCard__distanceInfo',
                showPrice && 'MiniCard__priceInfo',
              )}
            >
              {showPrice ? priceInfoNode : distancePOINode}
            </div>
          </div>
        </div>
      </div>
    </BpkCard>
  );
};

MiniCard.defaultProps = {
  showPrice: false,
};

export default withI18n(MiniCard);
