import React from 'react';

import BpkCard from '@skyscanner/backpack-web/bpk-component-card/src/BpkCard';
import { cssModules } from '@skyscanner/backpack-web/bpk-react-utils';
import ThumbIconSm from '@skyscanner/backpack-web/bpk-component-icon/sm/thumbs-up';
import LocationIconSM from '@skyscanner/backpack-web/bpk-component-icon/sm/location';
import BpkText, {
  TEXT_STYLES,
} from '@skyscanner/backpack-web/bpk-component-text';

import type { I18nService } from 'common/src/types/i18n';
import { getQueryParam } from 'common/src/services/url/url';

import { ACTION_TYPE } from '../../skyscanner-application/minievents/constants';
import { withI18n } from '../../skyscanner-application/i18n';
import { withElementEventTracker } from '../../skyscanner-application/element-events';
import { withMetrics } from '../../skyscanner-application/application-metrics';
import HotelStars from '../HotelStars';
import ReviewRating from '../ReviewRating';
import Distance from '../Distance';
import DistancePOI from '../DistancePOI';
import ImageItem from '../Common/ImageItem';

import Triangle from './Triangle';

import type {
  Metrics,
  ElementEventTracker,
} from '../../skyscanner-application/types';
import type { Maybe, ObjectMapping } from '../types';
import type { RecommendHotelCard } from '../../types/hotels-recommendation-response';

import STYLES from './RecommendCard.scss';

type RecommendCardProps = {
  i18n: I18nService;
  metrics: Metrics;
  elementEventTracker: ElementEventTracker;
  recommendHotel: RecommendHotelCard;
  position: number;
  searchCycleId?: Maybe<string>;
  guestType?: string;
  generalType: string;
  isGeneralType?: boolean;
  isMobile?: boolean;
  isViewedHotel?: boolean;
  onViewedHotelCardClick?: Function;
};

const cls = cssModules(STYLES);

const GUEST_KEY_MAP: ObjectMapping = {
  family: 'HotelCard_label_recommended_family',
  business: 'HotelCard_label_recommended_business',
  couple: 'HotelCard_label_recommended_couple',
  solo: 'HotelCard_label_recommended_solo',
};

const GENERAL_KEY_MAP = {
  BOOKED: 'booked',
  HIGHEST: 'highest',
  RECOMMEND: 'recommend',
  POPULAR: 'popular',
  SIMILAR: 'similar',
};

const RecommendCard = ({
  elementEventTracker,
  generalType,
  guestType,
  i18n,
  isGeneralType,
  isMobile,
  isViewedHotel,
  metrics,
  onViewedHotelCardClick,
  position,
  recommendHotel,
  searchCycleId,
  ...rest
}: RecommendCardProps) => {
  const {
    distanceInfo,
    id: hotelId,
    image,
    name: hotelName,
    numberOfStars,
    priceInfo,
    recommendReviewsCount,
    relevantPOI,
    reviewsSummaryInfo,
    url,
  } = recommendHotel;
  const price = priceInfo?.price ?? 0;
  const partnerId = priceInfo?.partnerId ?? '';

  const trackSearchResultSelected = () => {
    onViewedHotelCardClick &&
      onViewedHotelCardClick({
        hotelId,
        position,
        isMainPrice: true,
        isGoToHD: true,
        mainPriceArea: 'viewedCardOnDesktop',
        isViewedHotel: true,
      });
  };

  const trackRecommendHotelSelect = () => {
    metrics.searchResultSelected({
      hotelId,
      position,
      price,
      partnerId,
      searchId: searchCycleId,
      isRecommendHotel: true,
      stars: numberOfStars,
      basePrice: priceInfo?.basePrice,
      taxesAndFees: priceInfo?.taxesAndFees,
      dayviewCorrelationId: getQueryParam(
        window.location.href,
        'correlation_id',
      ),
      currency: i18n.culture.currency,
    });

    elementEventTracker.trackHotelsAction(
      ACTION_TYPE.DAY_VIEW_HOTEL_RECOMMEND_CARD_CLICKED,
    );
  };

  const style = { boxShadow: `0 1px 3px rgba(0, 0, 0, 0.12)` };

  const positiveReviewsCount =
    reviewsSummaryInfo && reviewsSummaryInfo.positiveReviewsCount;
  const shouldNotShowReviewsCount =
    generalType === GENERAL_KEY_MAP.SIMILAR ||
    (generalType === GENERAL_KEY_MAP.RECOMMEND &&
      Number(positiveReviewsCount) === 0);
  const generalTypeRecommendedTextMapping = {
    [GENERAL_KEY_MAP.BOOKED]:
      position === 1
        ? i18n.translate('HotelCard_label_general_most_booked_with_us')
        : position < 10 &&
          i18n.translate(
            `HotelCard_label_general_${position}_most_booked_with_us`,
          ),
    [GENERAL_KEY_MAP.POPULAR]: i18n.translate(
      'HotelCard_label_general_reviewed_by_x_travellers',
      {
        num: reviewsSummaryInfo && reviewsSummaryInfo.reviewSummaryCount,
      },
    ),
    [GENERAL_KEY_MAP.RECOMMEND]: i18n.translate(
      'HotelCard_label_general_x_positive_reviews',
      {
        num: positiveReviewsCount,
      },
    ),
    [GENERAL_KEY_MAP.HIGHEST]: i18n.translate(
      'HotelCard_label_general_highest_rated_by_travellers',
    ),
  };

  const distanceVariantNode = distanceInfo ? (
    <BpkText
      textStyle={TEXT_STYLES.caption}
      tagName="p"
      className={cls('RecommendCard__distanceVariant')}
    >
      <Distance distance={distanceInfo} />
    </BpkText>
  ) : null;

  const distancePOINode = relevantPOI ? (
    <BpkText
      textStyle={TEXT_STYLES.caption}
      tagName="p"
      className={cls('RecommendCard__distanceVariant')}
    >
      <DistancePOI relevantPOI={relevantPOI} />
    </BpkText>
  ) : (
    distanceVariantNode
  );

  return (
    <BpkCard
      className={cls('RecommendCard')}
      style={style}
      href={`${url}&isGeneralType=${isGeneralType}`}
      padded={false}
      onClick={
        isViewedHotel ? trackSearchResultSelected : trackRecommendHotelSelect
      }
      target="_blank"
      rel="opener"
      {...rest}
    >
      <div className={cls('RecommendCard__container')}>
        <div
          className={cls(
            'RecommendCard__imageInfo',
            'RecommendCard__imageInfo--newRecommend',
          )}
        >
          <ImageItem
            image={image}
            altText=""
            className={cls('RecommendCard__image')}
            containerButtonClassName={cls('RecommendCard__image--container')}
          />

          <React.Fragment>
            <div
              className={cls(
                'RecommendCard__hotelAndStars',
                'RecommendCard__hotelAndStars--newRecommend',
              )}
            >
              {numberOfStars! > 0 && <HotelStars stars={numberOfStars} />}
              <div className={cls('RecommendCard__hotelName')}>
                <BpkText
                  textStyle={TEXT_STYLES.bodyLongform}
                  tagName="p"
                  className={cls(
                    'RecommendCard__name',
                    'RecommendCard__name--newRecommend',
                  )}
                >
                  {hotelName}
                </BpkText>
              </div>
            </div>
          </React.Fragment>
        </div>
        <div
          className={cls(
            'RecommendCard__baseInfo',
            'RecommendCard__baseInfo--newRecommend',
          )}
        >
          <section
            className={cls(
              'RecommendCard__distanceInfo',
              'RecommendCard__distanceInfo--newRecommend',
            )}
          >
            <LocationIconSM />
            {distancePOINode}
          </section>

          <section>
            <div
              className={cls(
                'RecommendCard__ratingAndPriceInfo',
                'RecommendCard__reviewRating',
              )}
            >
              {reviewsSummaryInfo && reviewsSummaryInfo.reviewSummaryScore && (
                <ReviewRating
                  score={reviewsSummaryInfo.reviewSummaryScore}
                  count={reviewsSummaryInfo.reviewSummaryCount}
                  image={reviewsSummaryInfo.reviewSummaryScoreImageUrl}
                />
              )}
              {price > 0 ? (
                <div
                  className={cls(
                    'RecommendCard__priceInfo',
                    'RecommendCard__priceInfo--newRecommend',
                  )}
                >
                  <BpkText
                    textStyle={TEXT_STYLES.subheading}
                    tagName="p"
                    className={cls(
                      'RecommendCard__price',
                      'RecommendCard__price--newRecommend',
                    )}
                  >
                    {i18n.formatCurrency(price)}
                  </BpkText>
                  <BpkText
                    textStyle={TEXT_STYLES.caption}
                    tagName="p"
                    className={cls('RecommendCard__price--stayInfo')}
                  >
                    {i18n.translate('Price_label_aNight')}
                  </BpkText>
                </div>
              ) : null}
              {!shouldNotShowReviewsCount && (
                <Triangle className={cls('RecommendCard__triangle')} />
              )}
            </div>
            {!shouldNotShowReviewsCount && (
              <div
                className={cls(
                  'RecommendCard__travel',
                  'RecommendCard__travel--newRecommend',
                )}
              >
                <ThumbIconSm />
                <BpkText
                  textStyle={TEXT_STYLES.caption}
                  className={cls(
                    'RecommendCard__travelText',
                    'RecommendCard__travelText--newRecommend',
                  )}
                >
                  {isGeneralType
                    ? generalTypeRecommendedTextMapping[generalType]
                    : guestType &&
                      recommendReviewsCount &&
                      i18n.translate(GUEST_KEY_MAP[guestType], {
                        num: recommendReviewsCount,
                      })}
                </BpkText>
              </div>
            )}
          </section>
        </div>
      </div>
    </BpkCard>
  );
};

RecommendCard.defaultProps = {
  searchCycleId: null,
  guestType: undefined,
  isGeneralType: false,
  isMobile: false,
  isViewedHotel: false,
  onViewedHotelCardClick: () => {},
};

export default withElementEventTracker(withMetrics(withI18n(RecommendCard)));
