import React from 'react';

type Props = {
  className?: string;
};

const Triangle = ({ className }: Props) => (
  <svg
    width="33"
    height="6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M26.57 4.251A12.715 12.715 0 0033 6H0c2.258 0 4.476-.603 6.43-1.75L12.99.955a6.928 6.928 0 017.018 0l6.561 3.297z"
      fill="#0c838a"
    />
  </svg>
);

Triangle.defaultProps = {
  className: null,
};

export default Triangle;
