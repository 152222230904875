import type { ReactElement } from 'react';
import React from 'react';

import { cssModules } from '@skyscanner/backpack-web/bpk-react-utils';

import Swiper from '../Common/Swiper';

import STYLES from './ScrollerDesktop.scss';

const cls = cssModules(STYLES);

type Props = {
  cards: ReactElement[];
  itemsPerGroup?: number;
  spaceBetween?: number;
  prevNavBtnClassName?: string;
  nextNavBtnClassName?: string;
  buttonPosition?: string;
  needUpdate?: boolean;
  showImageBullets?: boolean;
  randomChildren?: boolean;
  enableMouseScroll?: boolean;
  noMarginLeft?: boolean;
  imageBulletsClassName?: string;
  activeBulletClassName?: string;
  bulletClassName?: string;
  className?: string;
  buttonDisable?: boolean;
  fixedWidth?: number;
};

const ScrollerDesktop = ({
  activeBulletClassName,
  bulletClassName,
  buttonDisable,
  buttonPosition,
  cards,
  className,
  enableMouseScroll,
  fixedWidth,
  imageBulletsClassName,
  itemsPerGroup,
  needUpdate,
  nextNavBtnClassName,
  noMarginLeft,
  prevNavBtnClassName,
  randomChildren,
  showImageBullets,
  spaceBetween,
}: Props) => (
  <Swiper
    randomChildren={randomChildren}
    enableMouseScroll={enableMouseScroll}
    itemsPerGroup={itemsPerGroup}
    spaceBetween={spaceBetween}
    prevNavBtnClassName={cls(prevNavBtnClassName)}
    nextNavBtnClassName={cls(nextNavBtnClassName)}
    buttonPosition={buttonPosition}
    className={cls('ScrollerDesktop__swiper', className)}
    needUpdate={needUpdate}
    imageBulletsClassName={cls(
      'ScrollerDesktop__swiper--imageBullets',
      imageBulletsClassName,
    )}
    bulletClassName={cls('ScrollerDesktop__swiper--bullet', bulletClassName)}
    activeBulletClassName={cls(
      'ScrollerDesktop__swiper--activeBullet',
      activeBulletClassName,
    )}
    showImageBullets={showImageBullets}
    buttonDisable={buttonDisable}
    fixedWidth={fixedWidth}
    noMarginLeft={noMarginLeft}
  >
    {cards}
  </Swiper>
);

ScrollerDesktop.defaultProps = {
  itemsPerGroup: 3,
  spaceBetween: 0,
  prevNavBtnClassName: null,
  nextNavBtnClassName: null,
  randomChildren: false,
  enableMouseScroll: false,
  buttonPosition: 'inner',
  needUpdate: false,
  showImageBullets: false,
  imageBulletsClassName: null,
  activeBulletClassName: null,
  bulletClassName: null,
  className: null,
  buttonDisable: true,
  fixedWidth: 0,
  noMarginLeft: false,
};

export default ScrollerDesktop;
